import * as React from 'react';
import { cn } from '@/utils';
const ButtonGroup = ({
  className,
  label,
  labelClasses,
  id,
  options,
  value: activeValue,
  onChange
}) => {
  const classes = cn({
    'flex items-center gap-2': true,
    [className]: className
  });
  const labelCn = cn({
    'm-0 text-sm cursor-pointer': true,
    [labelClasses]: labelClasses
  });
  const buttonCn = value => cn({
    button: true,
    'button-outline-primary': true,
    active: value === activeValue
  });
  const handleChange = option => {
    onChange(option);
  };
  return <div className={classes} data-sentry-component="ButtonGroup" data-sentry-source-file="ButtonGroup.jsx">
      {label && <label htmlFor={id} className={labelCn}>
          {label}
        </label>}
      <div className="button-group" data-toggle="buttons">
        {options && options.map(option => <button key={option.value} className={buttonCn(option.value)} type="button" onClick={() => handleChange(option.value)}>
            {option.label}
          </button>)}
      </div>
    </div>;
};
export { ButtonGroup };
export default ButtonGroup;