import React from 'react';
import Icon from '@/components/ui/Icon';
import { cn } from '@/utils';
export const CheckboxCard = ({
  ref,
  icon,
  iconType,
  image,
  label,
  badge,
  enabled,
  subtitle,
  value,
  disabled,
  multiple = false,
  setValue,
  getValues,
  name,
  onClick,
  children,
  errors,
  className,
  ...props
}) => {
  const isChecked = getValues(name) ? getValues(name).includes(value) : false;
  const handleClick = e => {
    e.preventDefault();
    if (disabled) return;
    if (multiple) {
      if (value === 'all') {
        setValue(name, [value], {
          shouldValidate: true
        });
      } else {
        const currentValue = getValues(name);
        const newValueArray = isChecked ? currentValue.filter(v => v !== value) : [...currentValue, value];
        const updatedValue = newValueArray.filter(v => v !== 'all');
        setValue(name, updatedValue, {
          shouldValidate: true
        });
      }
    } else {
      setValue(name, isChecked ? [] : [value], {
        shouldValidate: true
      });
    }
    if (onClick) {
      onClick(value);
    }
  };
  const classes = cn({
    'input-card': true,
    active: isChecked,
    disabled: disabled,
    error: errors && !disabled,
    [className]: className
  });
  return <label className={classes} onClick={handleClick} data-sentry-component="CheckboxCard" data-sentry-source-file="CheckboxCard.jsx">
      <input ref={ref} className="checkbox-input" type="checkbox" value={value} checked={isChecked} disabled={disabled} {...props} />

      {badge && badge}

      <div className="input-card-content">
        {image && <div className="input-card-image">
            <img src={image} alt={label} />
          </div>}

        {children && !image && <div className="input-card-image">{children}</div>}

        {icon && <Icon className="input-icon" name={icon} type={iconType} />}

        {label && <div className="flex flex-col items-start gap-1">
            {label && <span className="input-card-label">
              {label}
            </span>}

            {subtitle && <span className="input-card-subtitle">
                {subtitle}
              </span>}
          </div>}
      </div>
    </label>;
};
export default CheckboxCard;