import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import ImageUpload from './ImageUpload';
import RadioGroup from './RadioGroup';
import Select from './Select';
import Time from './Time';
import Icon from '@/components/ui/Icon';
import Slider from '@/components/ui/Slider';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/Tooltip/Tooltip';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
export const FieldTooltip = ({
  tooltipText
}) => {
  return <Tooltip data-sentry-element="Tooltip" data-sentry-component="FieldTooltip" data-sentry-source-file="Field.jsx">
      <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="Field.jsx">
        <Icon name="CircleHelp" data-sentry-element="Icon" data-sentry-source-file="Field.jsx" />
      </TooltipTrigger>
      <TooltipContent className="max-w-[300px]" data-sentry-element="TooltipContent" data-sentry-source-file="Field.jsx">
        <span>{tooltipText}</span>
      </TooltipContent>
    </Tooltip>;
};
const InputField = ({
  type,
  fetchFn,
  maxLimit,
  multi,
  options,
  inputProps
}) => {
  switch (type) {
    case 'phone':
      return <PhoneInput format={formatPhoneNumberIntl} {...inputProps} />;
    case 'select':
      return <Select fetchFn={fetchFn} maxLimit={maxLimit} multi={multi} options={options} {...inputProps} />;
    case 'slider':
      return <Slider {...inputProps} />;
    case 'textarea':
      return <textarea className={inputProps.className} {...inputProps} value={inputProps.value || ''} />;
    case 'time':
      return <Time {...inputProps} />;
    case 'radio-group':
      return <RadioGroup options={options} {...inputProps} />;
    case 'file':
      return <ImageUpload {...inputProps} />;
    case 'text':
    default:
      return <input className={inputProps.className} type={type} {...inputProps} />;
  }
};
const Field = ({
  ref,
  ...props
}) => {
  const {
    title,
    description,
    type = 'text',
    id,
    className,
    label,
    name,
    placeholder,
    value,
    isDisabled,
    iconBefore,
    iconAfter,
    errors,
    hints,
    onBlur,
    onChange,
    onFocus,
    onKeyDown,
    onInput,
    tooltipText,
    prefix,
    suffix,
    fetchFn,
    maxLength,
    maxLimit,
    multi = false,
    options,
    ...rest
  } = props;
  const classes = cn({
    'field-input-container': true,
    'field-icon-after': iconAfter,
    'field-error': errors,
    [`field-${type}`]: true,
    disabled: isDisabled,
    [className]: className
  });
  const prefixClasses = cn({
    content: true,
    'content-prefix': true,
    'content-left': true,
    'has-prefix': iconBefore
  });
  const suffixClasses = cn({
    content: true,
    'content-suffix': true,
    'content-right': true,
    'has-suffix': iconAfter
  });
  const inputClasses = cn({
    'has-prefix': prefix || iconBefore,
    'has-suffix': suffix || iconAfter,
    'flex-grow': type === 'select'
  });
  const inputWrapperClasses = cn({
    'input-wrapper': type !== 'radio-group',
    'overflow-visible': ['select'].includes(type) || type === 'time' || type === 'slider' || type === 'file'
  });
  const inputProps = {
    id,
    value,
    name,
    onChange,
    onBlur,
    onFocus,
    onKeyDown,
    onInput,
    maxLength,
    placeholder,
    disabled: isDisabled,
    ref,
    ...rest
  };
  const t = useTranslations();
  return <div className={classes} data-sentry-component="Field" data-sentry-source-file="Field.jsx">
      {title && <h5>
          {title}
          {tooltipText && <FieldTooltip tooltipText={tooltipText} />}
        </h5>}

      {label && <div className="flex items-center justify-between">
          <label htmlFor={id}>
            {label}
            {description && <p>{description}</p>}
            {tooltipText && !title && <FieldTooltip tooltipText={tooltipText} />}
          </label>

          {maxLength && <p className="text-sm opacity-70">
              {value?.length ?? 0} / {maxLength}
            </p>}
        </div>}

      <div className={inputWrapperClasses}>
        {iconBefore && <span className="content content-icon">{iconBefore}</span>}
        {prefix && <div className={prefixClasses}>{prefix}</div>}

        <InputField type={type} fetchFn={fetchFn} maxLimit={maxLimit} multi={multi} options={options} inputProps={{
        ...inputProps,
        className: inputClasses
      }} data-sentry-element="InputField" data-sentry-source-file="Field.jsx" />

        {suffix && <div className={suffixClasses}>{suffix}</div>}

        {iconAfter && <span className="content content-right content-icon">
            {iconAfter}
          </span>}
      </div>

      {hints && <div className="hints-container">
          {hints.map(hint => <p key={hint} className="info-message">
              {hint}
            </p>)}
        </div>}

      {errors && <div className="errors-container">
          {Array.isArray(errors) && errors.map(error => <p key={error} className="error-message">
              {error}
            </p>)}

          {typeof errors === 'object' && errors.type && <p className="error-message">
              {t(`form.error.${errors.type}`)}
            </p>}
        </div>}
    </div>;
};
export default Field;