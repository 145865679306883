'use client';

import React, { useEffect, useState } from 'react';
import Field from './Field';
import Button from '@/components/ui/Button';
import Icon from '@/components/ui/Icon';
import { cn } from '@/utils';
export const QuantityInput = ({
  ref,
  className,
  children,
  buttonVariant = 'ghost',
  cardVariant = false,
  icon,
  iconType,
  image,
  min = 1,
  max = 10,
  value: initialValue,
  label,
  step = 1,
  inputProps,
  field,
  onChange
}) => {
  const [value, setValue] = useState(initialValue || min);
  useEffect(() => {
    setValue(initialValue || min);
  }, [initialValue, min]);
  const handleMinus = () => {
    const newValue = Math.max(value - step, min);
    setValue(newValue);
    if (onChange) onChange(newValue);
  };
  const handlePlus = () => {
    const newValue = Math.min(value + step, max);
    setValue(newValue);
    if (onChange) onChange(newValue);
  };
  const handleChange = e => {
    let newValue = +e.target.value;
    newValue = Math.max(min, Math.min(newValue, max));
    setValue(newValue);
    if (onChange) onChange(newValue);
  };
  const classes = cn({
    [className]: className
  });
  if (cardVariant) {
    return <div className={classes}>
        <div className="input-card-content">
          {image && <div className="input-card-image">
              <img src={image} alt={label} />
            </div>}

          {icon && <Icon className="input-icon" name={icon} type={iconType} />}

          <div className="flex flex-col items-start w-full gap-4">
            {label && <span>{label}</span>}

            <div className="input-quantity">
              <Button variant={buttonVariant} onClick={handleMinus} icon="Minus" disabled={value <= min} />

              {!field && <Field type="number" className="text-center" value={value} min={min} max={max} ref={ref} step={step} onChange={handleChange} {...inputProps} />}

              {field && field}

              <Button variant={buttonVariant} onClick={handlePlus} icon="Plus" disabled={value >= max} />
            </div>

            {children}
          </div>
        </div>
      </div>;
  }
};
export default QuantityInput;