import * as SliderPrimitive from '@radix-ui/react-slider';
import * as React from 'react';
import { cn, formatNumber } from '@/utils';
const Slider = ({
  ref,
  showLabels = false,
  showIndicator = true,
  formatLabelFn = formatNumber,
  labelPrefix = '',
  labelSuffix = '',
  onChange,
  value,
  variant,
  className,
  ...props
}) => {
  const percentageLimit = 0.15;
  const bottomPercent = props?.min + (props?.max - props?.min) * percentageLimit;
  const topPercent = props?.max - (props?.max - props?.min) * percentageLimit;
  return <div className="flex flex-col w-full gap-3" data-sentry-component="Slider" data-sentry-source-file="Slider.jsx">
      <div>
        <SliderPrimitive.Root ref={ref} className={cn({
        'slider-input relative flex w-full touch-none select-none items-center': true,
        [`slider-input-${variant}`]: variant,
        [className]: className
      })} onValueChange={onChange} {...props} data-sentry-element="unknown" data-sentry-source-file="Slider.jsx">
          <SliderPrimitive.Track className="relative w-full h-4 overflow-hidden rounded-full grow slider-input-track" data-sentry-element="unknown" data-sentry-source-file="Slider.jsx">
            <SliderPrimitive.Range className="absolute h-full slider-input-value" data-sentry-element="unknown" data-sentry-source-file="Slider.jsx" />
          </SliderPrimitive.Track>
          <SliderPrimitive.Thumb className="block w-6 h-6 transition-colors border-2 rounded-full slider-input-thumb ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" data-sentry-element="unknown" data-sentry-source-file="Slider.jsx">
            {showIndicator && value?.[0] && <span className="text-sm slider-input-indicator">
                {labelPrefix}
                {formatLabelFn(value[0])}
                {labelSuffix}
              </span>}
          </SliderPrimitive.Thumb>
        </SliderPrimitive.Root>
      </div>

      {showLabels && <div className="flex justify-between">
          <span className={cn({
        'text-sm transition-opacity': true,
        'opacity-70': showIndicator && value?.[0] > bottomPercent,
        'opacity-0': showIndicator && value?.[0] <= bottomPercent
      })}>
            {labelPrefix}
            {formatLabelFn(props?.min)}
            {labelSuffix}
          </span>
          <span className={cn({
        'text-sm transition-opacity': true,
        'opacity-70': showIndicator && value?.[0] < topPercent,
        'opacity-0': showIndicator && value?.[0] >= topPercent
      })}>
            {labelPrefix}
            {formatLabelFn(props?.max)}
            {labelSuffix}
          </span>
        </div>}
    </div>;
};
Slider.displayName = SliderPrimitive.Root.displayName;
export { Slider };
export default Slider;