'use client';

import { Slot } from '@radix-ui/react-slot';
import React from 'react';
import Icon from '@/components/ui/Icon';
import Spinner from '@/components/ui/Spinner';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/Tooltip/Tooltip';
import { Link } from '@/i18n/routing';
import { cn } from '@/utils';
export const Button = ({
  ref,
  type = 'button',
  variant = 'primary',
  asChild,
  href,
  size,
  disabled,
  className,
  labelClasses,
  children,
  icon,
  iconRight,
  iconProps,
  iconType,
  iconVariant,
  loading,
  label,
  tooltip,
  ...props
}) => {
  const Comp = asChild ? Slot : 'button';
  const classes = cn({
    button: true,
    [`button-${variant}`]: variant,
    'button-icon': !label && !children || iconVariant,
    'button-loading': loading,
    [size]: size,
    [className]: className
  });
  const labelCn = cn({
    'button-label': true,
    [labelClasses]: labelClasses
  });
  if (href) {
    return <Link href={href} className={classes} {...props}>
        {icon && <Icon className="icon" type={iconType} name={icon} iconProps={iconProps} />}

        {label || children ? <span className={labelCn}>
            {label && label}
            {children}
          </span> : null}

        {iconRight && <Icon className="icon" type={iconType} name={iconRight} />}
      </Link>;
  }
  if (tooltip) {
    return <Tooltip>
        <TooltipTrigger asChild>
          <button type={type} className={classes} disabled={disabled} {...props}>
            {loading && <Spinner inline />}

            {icon && !loading && <Icon className="icon" type={iconType} name={icon} iconProps={iconProps} />}

            {label || children ? <span className="button-label">
                {label && label}
                {children}
              </span> : null}
          </button>
        </TooltipTrigger>
        <TooltipContent>
          {tooltip}
        </TooltipContent>
      </Tooltip>;
  }
  return <Comp type={type} disabled={disabled} className={classes} ref={ref} {...props} data-sentry-element="Comp" data-sentry-component="Button" data-sentry-source-file="Button.jsx">
      <>
        {loading && <Spinner inline />}

        {icon && !loading && <Icon className="icon" type={iconType} name={icon} iconProps={iconProps} />}

        {label || children ? <span className={labelCn}>
            {label && label}
            {children}
          </span> : null}

        {iconRight && <Icon className="icon" type={iconType} name={iconRight} iconProps={iconProps} />}
      </>
    </Comp>;
};
export default Button;