import { motion } from "motion/react";
import Icon from '@/components/ui/Icon';
import { cn } from '@/utils';
export const SpinnerIcon = ({
  icon = 'Music',
  className,
  size = 'sm',
  animating = true
}) => {
  const classes = cn({
    'spinner-icon': true,
    'inline-flex items-center justify-center': true,
    [className]: className
  });
  return <motion.div className={classes} animate={animating ? {
    opacity: [0.1, 1, 0.1]
  } : {
    opacity: 1
  }} transition={animating ? {
    duration: 2,
    repeat: Infinity,
    ease: 'easeInOut'
  } : {}} data-sentry-element="unknown" data-sentry-component="SpinnerIcon" data-sentry-source-file="SpinnerIcon.jsx">
      <Icon name={icon} size={size} data-sentry-element="Icon" data-sentry-source-file="SpinnerIcon.jsx" />
    </motion.div>;
};
export default SpinnerIcon;