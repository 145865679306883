import * as React from 'react';
import Icon from '@/components/ui/Icon';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
const RadioGroup = ({
  className,
  label,
  labelClasses,
  direction = 'row',
  disabled = false,
  id,
  options,
  optionsContainerClasses,
  value: activeValue,
  onChange
}) => {
  const t = useTranslations();
  const classes = cn({
    'flex items-center gap-2 w-full': true,
    [className]: className
  });
  const labelCn = cn({
    'm-0 text-sm cursor-pointer': true,
    [labelClasses]: labelClasses
  });
  const handleChange = option => {
    onChange(option);
  };
  return <div className={classes} data-sentry-component="RadioGroup" data-sentry-source-file="RadioGroup.jsx">
      {label && <label htmlFor={id} className={labelCn}>
          {label}
        </label>}

      <div className={optionsContainerClasses}>
        {options.map(option => <label className={cn('radio-button', {
        'mb-0': true,
        active: activeValue === option.id
      })} htmlFor={option.id} key={option.id}>
            <input disabled={disabled} onChange={() => handleChange(option.id)} type="radio" value={option.id} name="filter" id={option.id} checked={activeValue === option.id} />

            <div className={cn({
          'flex flex-col': true,
          'cursor-not-allowed': disabled
        })}>
              <div className="flex items-start gap-3">
                {option.icon && option.iconColor && <Icon className={cn({
              'w-12 h-12 p-3 m-0': true,
              [`bg-${option.iconColor}-light`]: true,
              [`text-${option.iconColor}`]: true
            })} name={option.icon} />}
                <div className="flex flex-col items-start">
                  <h6 className={cn({
                'm-0 text-base font-medium text-balance': true,
                'max-w-[18ch]': !option.description
              })}>
                    {t(option.label, {
                  shouldParse: true
                })}
                  </h6>

                  {option.description && <p className="text-sm text-muted-foreground text-balance">
                      {t(option.description, {
                  shouldParse: true
                })}
                    </p>}
                </div>
              </div>
            </div>
          </label>)}
      </div>
    </div>;
};
export { RadioGroup };
export default RadioGroup;